import { mapStores } from 'pinia'
import { useAuthStore } from "@/stores/authStore";

export const storeMixin = {
	computed: {
		...mapStores(useAuthStore)
	},
	methods: {
		async addItemToCart(productId, quantity, quantityOverride, removeItem) {
			try {
				this.cart.isUpdating = true
				this.cart.specialProductId = productId

				const cartCompleteItem = this.productStore.getItemFromCartComplete(productId * 1)

				let currentQuantityInCart = 0

				if (!quantityOverride && cartCompleteItem && cartCompleteItem.quantity && cartCompleteItem.quantity > 0) {
					currentQuantityInCart = cartCompleteItem.quantity
				}

				const payload = {
					CstKey: this.authStore.getCstKey,
					Quantity: removeItem ? 0 : currentQuantityInCart + quantity,
					SpecialProductId: parseInt(productId)
				}

				const response =
					await this.productStore.upsertItem(payload, this.authStore.getRegistrationId)
						.then(response => response)

				// grab updated quantities from API and re-set Pinia store
				if (response) {
					this.$toast.clear()
					await this.productStore.getCartItems(this.authStore.getRegistrationId, false)

					if (quantityOverride || removeItem) {
						this.resetLocalCartQuantities(true)
					} else {
						this.resetLocalCartQuantities(false)
					}

					// Below, will toggle off the loading spinner inside the button
					setTimeout(() => {
						this.setCartToInitialState()
					}, 250)

					this.showToast(removeItem, quantity)
				} else {
					this.setCartToErrorStateTemporary()
				}

			} catch (e) {
				console.error('Error in Store.vue, ', e)
				this.setCartToErrorStateTemporary()
			}
		},

		showToast(removeItem, quantity) {
			if (removeItem || quantity === 0) {
				this.$toast.open({
					message: "Item removed from cart",
					type: "success",
					duration: 6000,
					dismissible: true
				})
			} else {
				this.$toast.open({
					message: "Item quantity updated",
					type: "success",
					duration: 6000,
					dismissible: true
				})
			}
		},

		isUS(country) {
			return country.toUpperCase() === "UNITED STATES"
		},

		// used in the main 'Store' and 'Product Details' views
		async addToCart(productId) {
			if (this.cart.isUpdating || this.cart.errorDuringUpdate) return false

			if (this.localCart[productId] < 1) {
				this.$toast.clear()
				this.$toast.open({
					message: "Quantity must be greater than 0",
					type: "info",
					duration: 6000,
					dismissible: true
				})
				return false
			}

			await this.addItemToCart(productId, this.localCart[productId])
		},

		// returns an array of objects, for use in a 'Month' dropdown list
		buildCreditCardExpirationMonth() {
			let months = []
			let count = 1
			for (count; count <= 12; count++) {
				const month = count < 10 ? `0${count}` : count.toString()
				months.push({ name: month, abbreviation: month })
			}
			return months
		},
		// returns an array of objects, for use in a 'Year' dropdown list
		buildCreditCardExpirationYear() {
			let years = []
			let i = 8 // number of Years to show in the dropdown
			let currentYear = new Date().getFullYear()

			for (i; i > 0; i--) {
				const year = currentYear.toString()
				years.push({ name: year, abbreviation: year })
				currentYear++
			}
			return years
		},

		buildPersonalInfo(localState) {
			const {
				shippingAddress1, shippingAddress2, shippingCity, shippingCountry, firstName, email, lastName, phone
			} = localState

			let shippingZip = localState.shippingZip
			if (!shippingZip) shippingZip = "00000"

			return {
				Address1: shippingAddress1,
				Address2: shippingAddress2 ? shippingAddress2 : "",
				City: shippingCity,
				Country: shippingCountry ? shippingCountry : null,
				CstKey: this.authStore.userDetails.cstKey,
				FirstName: firstName,
				FPTKey: "00000",
				Email: email,
				LastName: lastName,
				Phone: phone ? phone : "",
				RegID: this.authStore.userDetails.registrationId,
				State: this.isUS(shippingCountry) ? this.shippingStateAbbv : this.shippingStateNonUSA,
				Zip: shippingZip,
				// NetSuite Internal Ids
				Camp_InternalID: this.authStore.userDetails.camp_InternalID ? this.authStore.userDetails.camp_InternalID : "",
				NS_FriendInternalId: this.authStore.userDetails.nS_FriendInternalId ? this.authStore.userDetails.nS_FriendInternalId : "",
				NS_AddressInternalId: this.authStore.userDetails.nS_AddressInternalId ? this.authStore.userDetails.nS_AddressInternalId : "",
			}
		},

		buildAddressInfo(localState) {
			const {
				billingAddress1, billingAddress2, billingCity, billingStateAbbv, billingStateNonUSA, billingCountry
			} = localState
			let billingAddressSameAsShipping = true

			if (this.billingAndShippingAreDifferent) billingAddressSameAsShipping = false
			else billingAddressSameAsShipping = true

			let billingState = this.isUS(billingCountry) ? billingStateAbbv : billingStateNonUSA

			let billingZip = localState.billingZip
			if (!billingZip) billingZip = "00000"

			return {
				BillingAccountName: "",
				BillingAddress1: billingAddress1,
				BillingAddress2: billingAddress2 ? billingAddress2 : "",
				BillingAddressSameAsShipping: billingAddressSameAsShipping,
				BillingCity: billingCity,
				BillingState: billingState,
				BillingZip: billingZip,
				BillingCountry: billingCountry === "UNITED STATES" ? "United States" : billingCountry,
			}
		},

		buildPaymentInfo(localState) {
			let {
				cardCCV, cardMonth, cardNumber, cardYear, checkAccountNumber, checkRoutingNumber,
				donationAmount, firstName, givingOptions, lastName, paymentType, segmentcode
			} = localState

			let cardExpireDate = null
			let paymentName = null

			if (paymentType === "creditCard") {
				paymentName = firstName.trim() + " " + lastName.trim()
				cardExpireDate = cardYear + "/" + cardMonth // "2024/12"
				checkAccountNumber = null
				checkRoutingNumber = null
			} else { // ACH
				cardNumber = null
				cardMonth = null
				cardYear = null
				cardCCV = null
			}

			return {
				DonationType: "",
				DonationAmount: donationAmount,
				PurchaseAmount: "0",
				DonationChoice: "",
				DonationOption: "",
				DonationDate: new Date(Date.now()).toISOString(),
				IsMonthlyDonation: givingOptions === 'monthly',
				TestamentType: "ESV",
				OptOut: true,
				BillingPaymentMethod: paymentType === "creditCard" ? "CreditCard" : "ACH",
				BillingRefNo: "",
				BillingAuthNo: "",
				BillingCardHolder: paymentName,
				BillingCCN: cardNumber,
				BillingExpireDate: cardExpireDate,
				BillingExpireMonth: cardMonth,
				BillingExpireYear: cardYear,
				BillingCVV: cardCCV,
				BillingCheckAccountNumber: checkAccountNumber,
				BillingRoutingNumber: checkRoutingNumber,
				SegmentCode: segmentcode,
			}
		},

		resetLocalCartQuantities(shouldPopulateWithActualQuantities) {  // deep copy & remove references of `cartTemplate` from pinia store
			this.localCart = { ...this.productStore.getCartTemplate }

			if (shouldPopulateWithActualQuantities) { //	Used by the 'Cart' page only
				this.productStore.cartComplete.forEach(cartItem => {
					const productId = cartItem.specialProductId.toString()
					this.localCart[productId] = cartItem.quantity
				})
			}
			return true
		},

		showFormattedPrice(val) {
			const isAValidNumber = this._isNumeric(val)

			if (!isAValidNumber) return false

			return new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD'
			}).format(val)

		},

		setCartToErrorStateTemporary() {
			this.cart.errorDuringUpdate = true

			setTimeout(() => {
				this.setCartToInitialState()
			}, 5000)
		},

		setCartToInitialState() {
			this.cart.errorDuringUpdate = false
			this.cart.isDeletingItem = false
			this.cart.isUpdating = false
			this.cart.specialProductId = ''
		},

		validateCartPrice() {
			const isValid = this.verifyDollarAmount(this.productStore.getCartTotal())

			if (!isValid) return false
			else this.donationAmount = this.productStore.getCartTotal()

			return true
		},

		verifyDollarAmount(givenValue) {
			if (!givenValue || typeof givenValue !== "number") return false
			if (givenValue < 1) return false

			return true
		},

		_isNumeric(val) {
			if (val) val = val.toString()

			return !isNaN(val) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
				!isNaN(parseFloat(val)) // ...and ensure strings of whitespace fail
		}
	},
}
