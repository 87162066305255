<template>
  <h2 class="title title-underline title-offset" :ref="id" :id="id" :class="color">{{ title }}</h2>
</template>

<script>
import inView from 'in-view';

export default {
  name: "H2",
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      position_title: '',
    }
  },
  methods: {
    handleScroll() {
      inView('.title-underline')
          .on('enter', el => {
            el.classList.add('inview');
          })
          .on('exit', el => {
            el.classList.remove('inview');
          });
    }

  },
  mounted() {
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>

<style scoped>

</style>