<template>
  <div>
    <div class="container container-l">
      <form v-if="localStateUpdated && !formSubmittedSuccessfully" action.prevent="submit" class="form">
        <div class="header">
          <H2 :title="h2.title" :color="h2.color" :id="h2.id" />

          <p class="hint">
            {{ cmsStore.getAcctMgmtForm.fgAllFieldsMarkedWith }} <span class="star">*</span>
            {{ cmsStore.getAcctMgmtForm.fgAreRequired }}.
          </p>
        </div>

        <div class="input-row">
          <Input type="text" v-model="firstName" :error="v$.firstName">
          {{ cmsStore.getAcctMgmtForm.fgFirstName }} <span class="star">*</span>
          </Input>

          <Input type="text" v-model="lastName" :error="v$.lastName">
          {{ cmsStore.getAcctMgmtForm.fgLastName }} <span class="star">*</span>
          </Input>
        </div>

        <div class="input-row">
          <Input type="text" v-model="address1" :error="v$.address1">
          {{ cmsStore.getAcctMgmtForm.fgStreetAddress1 }} <span class="star">*</span>
          </Input>

          <Input type="text" v-model="address2" :error="v$.address2">
          {{ cmsStore.getAcctMgmtForm.fgStreetAddress2 }}
          </Input>
        </div>

        <div class="input-row">
          <Input type="text" v-model="city" :error="v$.city">
          {{ cmsStore.getAcctMgmtForm.fgCity }} <span class="star">*</span>
          </Input>

          <!-- Non-USA countries will have a regular text input for the 'state' data field -->
          <Input v-if="!isUS(country)" type="text" v-model="stateNonUSA" :error="v$.stateNonUSA">
          {{ cmsStore.getAcctMgmtForm.fgState }} <span class="star">*</span>
          </Input>
          <Select v-if="isUS(country)" :list="apiCallsStore.stateList" @select-id="setStateAbbv" v-model="state"
            :error="v$.state">
            {{ cmsStore.getAcctMgmtForm.fgState }} <span class="star">*</span>
          </Select>

        </div>

        <div class="input-row">
          <Input type="text" :isDisabled="!isCountryUSA" v-model="zip" :error="v$.zip">
          {{ cmsStore.getAcctMgmtForm.fgZipCode }} <span v-if="isCountryUSA" class="star">*</span>
          </Input>

          <Select :list="apiCallsStore.countryList" @select-item="setCountry" v-model="country" :error="v$.country">
            {{ cmsStore.getAcctMgmtForm.fgCountry }} <span class="star">*</span>
          </Select>
        </div>

        <div class="input-row">
          <Input type="text" v-model="email" :error="v$.email">
          {{ cmsStore.getAcctMgmtForm.fgEmail }} <span class="star">*</span>
          </Input>

          <Input type="tel" v-model="phone" :error="v$.phone">
          {{ cmsStore.getAcctMgmtForm.fgPhone }}
          </Input>
        </div>

        <div class="input-row password-row">
          <Input :type="passwordType" which="password" allowAutofill="new-password" v-model="password"
            :error="v$.password" @iconClick="this.handleIconClick" :animateIcon="shouldPasswordIconAnimate">
          {{ cmsStore.getAcctMgmtForm.fgNewPassword }}
          </Input>

          <Input :type="passwordConfirmType" which="passwordConfirm" allowAutofill="new-password"
            v-model="confirmPassword" :error="v$.confirmPassword" @iconClick="this.handleIconClick"
            :animateIcon="shouldPasswordConfirmIconAnimate">
          {{ cmsStore.getAcctMgmtForm.fgConfirmNewPassword }}
          </Input>
        </div>

        <div class="error-row">
          <p v-if="formErrors" class="register-error">{{ cmsStore.getAcctMgmtForm.fgThereAreErrors }}</p>
          <div class="" v-if="registerError.length > 0" v-for="error in registerError">
            <p class="register-error">{{ error }}</p>
          </div>
        </div>

        <div class="buttons-row">
          <button class="btn btn-default" v-bind:class="{ 'form-actively-submitting': formActivelySubmitting }"
            @click.prevent="formSubmit">{{ cmsStore.getAcctMgmtForm.fgSave }}
          </button>

          <router-link :to="{ name: 'Friend' }">
            <button class="btn btn-alternate btn-darker"
              v-bind:class="{ 'form-actively-submitting': formActivelySubmitting }">{{ cmsStore.getAcctMgmtForm.fgCancel
              }}
            </button>
          </router-link>

        </div>
      </form>

      <div v-if="formSubmittedSuccessfully">
        <p class="text">{{ cmsStore.getAcctMgmtForm.fgThankYou }}</p>
        <router-link :to="{ name: 'Friend' }">
          <button class="btn btn-alternate btn-darker">{{ cmsStore.getAcctMgmtForm.fgBackToHome }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import H2 from '../components/H2'
import Input from '@/components/Input'
import { inputMixin } from "@/mixins/inputMixin"
import { email, helpers, maxLength, required, sameAs } from '@vuelidate/validators'
import { mapStores } from 'pinia'
import Select from '@/components/Select'
import { storeMixin } from "@/mixins/storeMixin"
import TitlePage from '@/components/TitlePage'
import { useApiCallsStore } from "@/stores/apiCallsStore"
import { useAuthStore } from "@/stores/authStore"
import { useCmsStore } from "@/stores/cmsStore"
import { useDesignStore } from "@/stores/designStore"
import useVuelidate from '@vuelidate/core'

export default {
  name: 'AccountManagementForm',
  mixins: [inputMixin, storeMixin],
  components: {
    TitlePage,
    Input,
    Select,
    H2
  },
  setup: () => ({ v$: useVuelidate({ $lazy: false, $autoDirty: true }) }),
  data() {
    return {
      h2: {
        title: ' User Information',
        id: 't1',
        color: 'color-black'
      },
      formActivelySubmitting: false,
      formErrors: false,
      isCountryUSA: true,
      registerError: [],
      address1: '',
      address2: '',
      city: '',
      confirmPassword: '',
      country: 'United States',
      firstName: '',
      email: '',
      lastName: '',
      password: '',
      passwordType: "password",
      passwordConfirmType: "password",
      phone: '',
      shouldPasswordIconAnimate: false,
      shouldPasswordConfirmIconAnimate: false,
      state: '',
      stateAbbv: '',
      stateNonUSA: '',
      zip: '',
      localStateUpdated: false,
      formSubmittedSuccessfully: false,
    }
  },
  computed: {
    ...mapStores(useApiCallsStore, useAuthStore, useCmsStore, useDesignStore),
  },
  async created() {
    await this.getStateAndCountryLists()
      .then(results => {
        if (results) {
          this.buildLocalStateFromStore()
        }
      })
  },
  mounted() {
    const pageTitle = this.cmsStore.getAcctMgmtForm.fgMyAccount ? this.cmsStore.getAcctMgmtForm.fgMyAccount : 'My account'
    const pageSubtitle = this.cmsStore.getAcctMgmtForm.fgEditProfile ? this.cmsStore.getAcctMgmtForm.fgEditProfile : 'Edit Profile'

    this.designStore.setTitlePage({
      title: pageTitle,
      sub_title: pageSubtitle,
      img_class: 'bg-account-management'
    })
  },
  methods: {
    async apiCallToUpdateFriend(updateFriend) {
      try {
        return await this.apiCallsStore.UpdateFriend(updateFriend)
          .then(response => {
            return response
          })
      } catch (e) {
        console.error(e)
      }
    },
    async buildLocalStateFromStore() {
      this.address1 = this.authStore.userDetails.address1
      this.address2 = this.authStore.userDetails.address2
      this.city = this.authStore.userDetails.city
      this.country = this.authStore.userDetails.country
      this.firstName = this.authStore.userDetails.firstName
      this.email = this.authStore.userDetails.email
      this.lastName = this.authStore.userDetails.lastName
      this.phone = this.authStore.userDetails.phone
      this.zip = this.authStore.userDetails.zip

      if (this.isUS(this.country)) {
        this.isCountryUSA = true
        const userState = this.apiCallsStore.stateList.filter(state => state.abbreviation === this.authStore.userDetails.state)

        if (userState && userState.length === 1) {
          this.state = userState[0].name
          this.stateAbbv = userState[0].abbreviation
        } else {
          this.state = this.authStore.userDetails.state
        }
      } else {
        this.isCountryUSA = false
        this.stateNonUSA = this.authStore.userDetails.state
      }

      this.localStateUpdated = true
    },

    buildUpdatedFriendJson() {
      const { address1, address2, city, country, firstName, email, lastName, password, phone, zip } = this

      return {
        RegistrationId: this.authStore.userDetails.registrationId,
        Address1: address1 ? address1 : null,
        Address2: address2 ? address2 : null,
        City: city ? city : null,
        Country: country ? country : null,
        CstKey: this.authStore.userDetails.cstKey || null,
        FirstName: firstName ? firstName : null,
        Email: email ? email : null,
        LastName: lastName ? lastName : null,
        Password: password ? password : null,
        Phone: phone ? phone : "",
        State: this.isUS(country) ? this.stateAbbv : this.stateNonUSA,
        Zip: zip ? zip : '00000',
      }
    },
    async formSubmit() {
      if (this.formActivelySubmitting) return false

      this.setFormErrorMessage(false) // reset form error message
      this.setRegisterErrorMessage() // reset register error message
      this.setFormBeingActivelySubmitted(true)

      let loader = null
      const isFormValid = await this.v$.$validate()
      try {
        loader = this.$loading.show()
        if (isFormValid) {
          const updatedFriend = this.buildUpdatedFriendJson()
          const response = await this.apiCallToUpdateFriend(updatedFriend)
          await this.handleServerResponse(response)
        } else {
          this.setFormErrorMessage(true)
          return false
        }
      } catch (e) {
        console.error('Error in AccountManagementForm.vue', e)
      } finally {
        loader.hide()
        this.setFormBeingActivelySubmitted(false)
      }

    },
    async handleServerResponse(res) {
      let errMessage = "There was an issue updating your account information, please try again."

      if (res && res.hasOwnProperty('cstKey')) {
        await this.authStore.loginUser(res, true)
          .then(() => {
            this.formSubmittedSuccessfully = true
          },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
            }
          )
      }

      if (res && typeof (res) === 'string') {
        if (res === 'Already a current Friend' || res === 'Already a current Gideon') {
          errMessage = "That email is already associated with another account, please choose another email address."
        } else {
          errMessage = res
        }
      } else if (res && Array.isArray(res)) {
        this.setFormErrorMessage(true)
        return this.setRegisterErrorMessage(res)
      }
      this.setRegisterErrorMessage([errMessage])
    },
    async getStateAndCountryLists() {
      return await this.apiCallsStore.GetStatesAndCountries(true, this.country)
    },
    setFormErrorMessage(status) {
      this.formErrors = status
    },
    setRegisterErrorMessage(message) {
      this.registerError = []
      if (message) {
        message.map(msg => this.registerError.push(msg))
      }
    },
    setFormBeingActivelySubmitted(status) {
      this.formActivelySubmitting = status
    },
    setState(payload) {
      this.state = payload.name
    },
    setStateAbbv(payload) {
      this.stateAbbv = payload
    },
    setCountry(payload) {
      this.country = payload.name

      if (payload.name && this.isUS(payload.name)) {
        this.isCountryUSA = true
        this.stateNonUSA = ''
        this.zip = ''
      } else {
        this.state = ''
        this.stateAbbv = ''
        this.zip = ''
        this.isCountryUSA = false
      }
    },
  },
  validations() {
    const atLeastOneLetter = value => {
      if (value && value.length > 0) {
        return /[a-zA-Z]/.test(value)
      } else return true
    }
    const atLeastOneNumber = value => {
      if (!value) return true
      return /[0-9]/.test(value)
    }
    const atLeastSixCharactersLong = value => {
      if (!value) return true
      return value.length > 5
    }
    const passwordAndEmailMustBeDifferent = value => !value.includes(this.email)
    const requireZipForUSAOnly = value => {
      if (this.isUS(this.country) && !value) {
        return false
      } else if (this.isUS(this.country)) return true

      return true
    }
    const validStateForNonUSA = value => {
      if (!value && !this.isUS(this.country)) return false
      if (!this.isUS(this.country)) {
        if (value.length > 1) return true
      } else if (this.isUS(this.country)) return true

      return false
    }
    const validStateForUSA = value => {
      if (!value && this.isCountryUSA) return false
      if (this.isUS(this.country)) {
        const userState = this.apiCallsStore.stateList.filter(state => state.abbreviation === value || state.name === value)
        if (userState && userState.length > 0) return true
      } else if (!this.isUS(this.country)) return true

      return false
    }

    return {
      confirmPassword: {
        sameAsRawValue: sameAs(this.password),
      },
      city: {
        required: helpers.withMessage('City is required', required),
        maxLengthValue: helpers.withMessage('City cannot be longer than 50 characters', maxLength(50)),
      },
      email: {
        required: helpers.withMessage('Email is required', required),
        maxLengthValue: helpers.withMessage('Email cannot be longer than 100 characters', maxLength(100)),
        email,
      },
      firstName: {
        required: helpers.withMessage('First name is required', required),
        maxLengthValue: helpers.withMessage('First name cannot be longer than 50 characters', maxLength(50)),
      },
      lastName: {
        required: helpers.withMessage('Last name is required', required),
        maxLengthValue: helpers.withMessage('Last name cannot be longer than 50 characters', maxLength(50)),
      },
      password: {
        atLeastOneLetter: helpers.withMessage('At least one letter is required', atLeastOneLetter),
        atLeastSixCharactersLong: helpers.withMessage('Password must be at least 6 characters long', atLeastSixCharactersLong),
        atLeastOneNumber: helpers.withMessage('At least one number is required', atLeastOneNumber),
        passwordAndEmailMustBeDifferent: helpers.withMessage('Password cannot contain your email address', passwordAndEmailMustBeDifferent),
      },
      state: {
        validStateForUSA: helpers.withMessage('State selection is invalid', validStateForUSA),
      },
      stateNonUSA: {
        validStateForNonUSA: helpers.withMessage('State selection is invalid', validStateForNonUSA),
      },
      zip: {
        requireZipForUSAOnly: helpers.withMessage('Zip code is required', requireZipForUSAOnly),
      },
      country: {
        required: helpers.withMessage('Country is required', required),
      },
      address1: {
        required: helpers.withMessage('Address is required', required),
        maxLengthValue: helpers.withMessage('Address 1 cannot be longer than 200 characters', maxLength(200)),
      },
      address2: {
        maxLengthValue: helpers.withMessage('Address 2 cannot be longer than 200 characters', maxLength(200)),
      },
    }
  }
}
</script>


<style lang="scss" scoped>
.container {
  padding: 46px 115px 120px;

  @media screen and (max-width: $md) {
    padding: 25px 15px 50px;
  }
}

.header {
  h2 {
    color: #0c2e3c;
  }
}

.form {
  max-width: 857px;
}

.title-label {
  font-size: 36px;
  font-weight: 500;
  color: $blue-darker;
}

.hint {
  color: $blue;
  font-size: 16px;
  margin-top: 43px;
  margin-bottom: 21px;

  span {
    color: $red;
    font-size: 20px;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 22px;
    margin-top: 20px;
  }
}

.error-row {
  display: block;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  :deep {
    .select-container {
      .title-label {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 700;
        line-height: 33px;
        color: #0c2e3c;
      }

    }

  }

  div:first-child {
    margin-right: 37px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 12px;

    div:first-child {
      margin-bottom: 16px;
      margin-right: 0;
    }
  }
}

.form-actively-submitting {
  cursor: not-allowed;
}

.input-error {
  color: $red;
  font-size: 18px;
  font-weight: 500;
}

.register-error {
  color: $red;
  font-size: 18px;
  font-weight: 500;
}

.star {
  color: $red;
  font-size: 20px;
}

.password-row {
  margin-top: 85px;

  @media screen and (max-width: $md) {
    margin-top: 55px;
  }

  @media screen and (max-width: $sm) {
    margin-top: 24px;
  }
}

.buttons-row {
  display: flex;
  justify-content: center;
  margin-top: 80px;

  button:first-child {
    margin-right: 50px;
  }

  @media screen and (max-width: $md) {
    margin-top: 55px;

    button:first-child {
      margin-right: 25px;
    }

    button {
      max-width: 140px;
      min-width: 140px;
    }
  }

  @media screen and (max-width: $sm) {
    margin-top: 35px;

    button:first-child {
      margin-right: 15px;
    }
  }
}

.btn-darker {
  &:before {
    background: $blue-darker;
  }
}
</style>
