<template>
  <div>
    <AccountManagementForm/>
  </div>
</template>

<script>
import AccountManagementForm from '@/components/AccountManagementForm'
import {mapStores} from 'pinia'
import {useCmsStore} from "@/stores/cmsStore"

export default {
  name: 'AccountManagement',
  components: {
    AccountManagementForm
  },
  data() {
    return {
      cms: {
        loaded: false,  // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: ["account_management_form", "h2", "input", "select", "title_page"],
          LanguageCode: "",
          PageName: "account_management",
          MinistryUpdates: false
        },
        page: {},
      },
    }
  },
  computed: {
    ...mapStores(useCmsStore),
  },
  async created() {
    this.setContentLoaded(false)
    await this.fetchCmsData()
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setContentLoaded(true)
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus
    },
  },
}
</script>

<style lang="scss" scoped>

</style>